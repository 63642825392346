
































































































































.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.header-login {
  display: grid;

  >div {
    width: 100%;
  }

  justify-content: center;
}
