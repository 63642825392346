

























































































































.input-class .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px !important;
  margin: 0px 0px -7px 0px !important;
}

.box-card-actions .el-card__body {
  padding: 0px 20px 0px 20px !important;
}

.button-tittle {
  font-size: 0.7rem;
}
.action-tittle {
  font-size: 0.9rem;
  font-weight: bold;
  -ms-transform: translateY(28%);
  transform: translateY(28%);
}

.gray {
  color: #888888;
}
