.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 4s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 1000px;
  margin: 0px auto;
  padding: 10px 10px 0px 10px;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
  overflow-x: auto;
}

.modal-body {
  overflow-y: auto;
  max-height: 485px;
  padding: 24px 24px 16px;
}

.modal-title {
  margin: 0;
}